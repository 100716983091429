<template>
    <div class="container mmgal" ref="container" @scroll="onScroll">
        <div class="mmgal--header">
            <div class="mmgal--title">
                추천이 필요하거나<br />
                빠른 상담이 필요하다면?
            </div>
            <button class="mmgal--btn" @click="$gotoWeb('http://pf.kakao.com/_yzxhwC/chat')">
                전문 매니저에게 문의하기 >
            </button>
            <img class="mmgal--icon" src="/img/main-menu-group/icon.png" />
        </div>

        <div class="ui-border-line ui-mt-3" />

        <ul>
            <template v-if="!init || artistList.length">
                <template v-for="(row, key) in artistList">
                    <template v-if="row.movUrl">
                        <li :key="key" class="mmgal--youtube--item">
                            <div class="mmgal--youtube--player">
                                <youtube
                                    :video-id="$youtube.getIdFromUrl(row.movUrl)"
                                    :player-vars="{ rel: 0, showinfo: 0 }"
                                    :style="{
                                        width: '480px',
                                        height: `${$lib.getRatioHeight(16 / 9, 480)}px`,
                                    }"
                                />
                            </div>
                            <div class="mmgal--youtube--info" @click="gotoProfile(row)">
                                <div
                                    class="mmgal--youtube--thumbnail ui-bg-img"
                                    :style="{
                                        backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl)})`,
                                    }"
                                />

                                <div class="mmgal--youtube--contents">
                                    <div class="mmgal--youtube--name ui-ellipsis">{{ row.teamName }}</div>
                                    <div class="mmgal--youtube--btn">프로필 보기 ></div>
                                </div>
                            </div>
                        </li>
                    </template>
                    <template v-else>
                        <a-item :key="key" :row="row" />
                    </template>
                    <div class="ui-border-line" :key="`${key}-line`"></div>
                </template>
            </template>
            <blank-search v-else text="찜한 의뢰가 없습니다" />
        </ul>
    </div>
</template>

<script>
import AItem from '@/components/artist/AItem.vue'
import BlankSearch from '@/components/blank/BlankList.vue'

import { useCacheStore } from '@/pinia/modules/cache'
import type { IMainMenuData } from '@/flow/api'

export default {
    name: 'ClientMainMenuGroupArtistList',
    components: {
        AItem,
        BlankSearch,
    },
    data() {
        const cacheStore = useCacheStore()
        const cache = cacheStore.getItem('ClientMainMenuGroupArtistList') || {}

        return {
            init: false,
            artistList: [],
            que: {
                mainArtistData: false,
            },
            scroll: {
                lastY: 0,
            },
            ...cache,
        }
    },
    computed: {
        mainMenuGroupUid() {
            return this.$route.params.mainMenuGroupUid
        },
    },
    created() {
        this.getMainArtistData(true)
    },
    beforeRouteEnter(to, from, next) {
        next(Vue => {
            if (Vue.init) {
                Vue.$refs.container.scrollTop = Vue.scroll.lastY
            } else {
                Vue.getMainArtistData(true)
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        const cacheStore = useCacheStore()

        this.$store.commit('setGnbTitle', null)

        if (to.name === 'home') {
            this.scroll.lastY = 0
            this.init = false
        } else {
            this.scroll.lastY = this.$refs.container.scrollTop
        }

        cacheStore.setItem('ClientMainMenuGroupArtistList', {
            init: this.init,
            artistList: this.artistList,
            que: this.que,
            scroll: this.scroll,
        })

        next()
    },
    methods: {
        getMainArtistData(init = false) {
            if (this.que.mainArtistData) return
            this.que.mainArtistData = true

            const req = {
                method: 'get',
                url: `/etc/main/artist`,
                params: {
                    main_menu_group_uid: this.mainMenuGroupUid,
                },
            }

            this.$http(req)
                .then(async ({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        const mainMenuData: IMainMenuData = data.response?.mainMenuData?.[0]
                        const title = mainMenuData?.contents ?? ''
                        const artistList = await Promise.all(
                            (mainMenuData?.artistData ?? [])
                                .map(async ({ artistGenre, ...row }) => {
                                    // const artistData = await this.getProfile(row.artistidx)

                                    if (row.artistidx) {
                                        return {
                                            ...row,
                                            genre: artistGenre,
                                        }
                                    }

                                    return null
                                })
                                .filter(row => !!row)
                        )

                        this.artistList = artistList

                        if (title) {
                            this.$store.commit('setGnbTitle', title)
                        }
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
                .then(() => {
                    this.init = true
                    setTimeout(() => {
                        this.que.mainArtistData = false
                    }, 1000)
                })
        },
        /**
         * @deprecated
         */
        getProfile(artistidx: number) {
            const req = {
                method: 'get',
                url: `/artist/profile/${artistidx}`,
            }

            return this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        const artistData = data.response?.artistData || {}

                        if (artistData.artistidx) {
                            return artistData
                        }
                    }
                    return null
                })
                .catch(() => {
                    return null
                })
        },
        gotoProfile(row) {
            this.$router.push({
                path: `/artist/profile/${row.artistidx}`,
                query: {
                    main_menu_artist_uid: row.mainMenuArtistUid,
                },
            })
        },
        onScroll() {
            // switch (this.$route.name) {
            //   case 'ClientMainMenuGroupArtistList': {
            //     const scrollTop = this.$refs.container?.scrollTop ?? 0
            //     const scrollHeight = this.$refs.container?.scrollHeight ?? 0
            //     if (scrollHeight <= scrollTop + window.innerHeight) {
            //       this.getMainArtistData()
            //     }
            //     break
            //   }
            // }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.mmgal {
    .mmgal--header {
        background-color: #eeeeee;
        border-radius: 7.5px;
        position: relative;
        padding: 25px 48px 20px;

        .mmgal--title {
            font-weight: 600;
            font-size: 1.9rem;
            color: #000;
            margin-bottom: 7px;
        }

        .mmgal--btn {
            font-weight: 400;
            font-size: 1.9rem;
            color: $color-deepLavender;
            background: none;
            border: none;
        }

        .mmgal--icon {
            position: absolute;
            top: 30px;
            right: 57px;
            width: 65px;
            display: block;
        }
    }

    ul {
        .mmgal--youtube--item {
            padding-top: 38px;
            padding-bottom: 20px;

            &:first-child {
                margin-top: -8px;
            }

            .mmgal--youtube--player {
                margin-bottom: 16.5px;
            }

            .mmgal--youtube--info {
                display: flex;
                cursor: pointer;

                .mmgal--youtube--thumbnail {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                    min-width: 40px;
                }
                .mmgal--youtube--contents {
                    display: flex;
                    align-items: center;
                    color: #292929;
                    font-size: 2rem;
                    width: calc(100% - 50px);

                    .mmgal--youtube--name {
                        font-weight: 600;
                        height: 2.8rem;
                    }
                    .mmgal--youtube--btn {
                        font-weight: 400;
                        padding: 0 0.5rem;
                        min-width: fit-content;
                    }
                }
            }
        }
    }
}
</style>
