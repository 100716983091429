var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "container mmgal",
      on: { scroll: _vm.onScroll }
    },
    [
      _c("div", { staticClass: "mmgal--header" }, [
        _vm._m(0),
        _c(
          "button",
          {
            staticClass: "mmgal--btn",
            on: {
              click: function($event) {
                return _vm.$gotoWeb("http://pf.kakao.com/_yzxhwC/chat")
              }
            }
          },
          [_vm._v(" 전문 매니저에게 문의하기 > ")]
        ),
        _c("img", {
          staticClass: "mmgal--icon",
          attrs: { src: "/img/main-menu-group/icon.png" }
        })
      ]),
      _c("div", { staticClass: "ui-border-line ui-mt-3" }),
      _c(
        "ul",
        [
          !_vm.init || _vm.artistList.length
            ? [
                _vm._l(_vm.artistList, function(row, key) {
                  return [
                    row.movUrl
                      ? [
                          _c(
                            "li",
                            { key: key, staticClass: "mmgal--youtube--item" },
                            [
                              _c(
                                "div",
                                { staticClass: "mmgal--youtube--player" },
                                [
                                  _c("youtube", {
                                    style: {
                                      width: "480px",
                                      height:
                                        _vm.$lib.getRatioHeight(16 / 9, 480) +
                                        "px"
                                    },
                                    attrs: {
                                      "video-id": _vm.$youtube.getIdFromUrl(
                                        row.movUrl
                                      ),
                                      "player-vars": { rel: 0, showinfo: 0 }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mmgal--youtube--info",
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoProfile(row)
                                    }
                                  }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "mmgal--youtube--thumbnail ui-bg-img",
                                    style: {
                                      backgroundImage:
                                        "url(" +
                                        _vm.$lib.cdnUrl(row.teamImgUrl) +
                                        ")"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mmgal--youtube--contents" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mmgal--youtube--name ui-ellipsis"
                                        },
                                        [_vm._v(_vm._s(row.teamName))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mmgal--youtube--btn" },
                                        [_vm._v("프로필 보기 >")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      : [_c("a-item", { key: key, attrs: { row: row } })],
                    _c("div", {
                      key: key + "-line",
                      staticClass: "ui-border-line"
                    })
                  ]
                })
              ]
            : _c("blank-search", { attrs: { text: "찜한 의뢰가 없습니다" } })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mmgal--title" }, [
      _vm._v(" 추천이 필요하거나"),
      _c("br"),
      _vm._v(" 빠른 상담이 필요하다면? ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }